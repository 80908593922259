import * as React from 'react'
import Layout from '../../components/layout'
const Parasite = ({ data }) => {
  return (
    <Layout pageTitle="Parasite & Host">
      <div className='mt-6 max-w-md border-2 border-purple-500'>

      </div>
      <div className='mt-6'>
          <p>Test of stuff</p>
      </div>
    </Layout>
  )
}

export default Parasite